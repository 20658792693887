import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css'],
})
export class QuizComponent implements OnInit {
  // Should be an enum
  @Input() options: any;
  @Input() nextRoute?: string;

  @Output() choice = new EventEmitter();

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  optionList() {
    const optionList = [];
    for (const option in this.options) {
      if (!this.options.hasOwnProperty(option)) {
        continue;
      }

      if (isNaN(Number(this.options[option]))) {
        optionList.push(this.options[option]);
      }

    }

    return optionList;
  }


  submit(event: Event) {
    const target = event.target as any;
    this.choice.emit(target.quiz.value);
    if (this.nextRoute) {
      this.router.navigate([this.nextRoute]);
    }
    return false;
  }
}
