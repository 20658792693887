import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {WrongDialogComponent} from '../wrong-dialog/wrong-dialog.component';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {
  static ROUTE = '';

  constructor(private _router: Router, private dialog: MatDialog) { }

  ngOnInit() {
  }


  submit(data: Event) {
    const target = data.target as any;
    if (target.password.value === '500what') {
      this._router.navigate(['/bellamy']);
    } else {
      this.dialog.open(WrongDialogComponent);
      return false;
    }
  }
}
