import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'underscore';
import {Bet, FigureItOut, Quiz, Reindeer, RetirementPlan, SmallWinnings} from '../quiz.storage';
import {EndFailComponent} from '../end-fail/end-fail.component';
import {FirstSuccessComponent} from '../end-success/first-success/first-success.component';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css'],
})
export class GameComponent implements OnInit {

  static MAPPING = new Map([
    ['1', 'bellamy'],
    ['2', 'tullis'],
    ['3', 'septimus'],
    ['4', 'amalasuintha'],
    ['5', 'hrotsuitha'],
    ['6', 'eadburg'],
    ['7', 'telesphoros'],
    ['8', 'faustus'],
    ['9', 'lucilla'],
    ['10', 'kassandra'],
    ['11', 'goma'],
    ['12', 'kyros'],
    ['13', 'thancmar'],
    ['14', 'hulderic'],
    ['15', 'antonia'],
  ]);

  static REVERSE_MAPPING = {}

  Reindeer = Reindeer;
  Bet = Bet;
  RetirementPlan = RetirementPlan;
  SmallWinnings = SmallWinnings;
  FigureItOut = FigureItOut;

  EndFailComponent = EndFailComponent;


  private _route: string;

  constructor(private activatedRoute: ActivatedRoute, private quiz: Quiz, private router: Router) {
  }

  ngOnInit() {
    GameComponent.MAPPING.forEach((key, value) => {
      GameComponent.REVERSE_MAPPING[key] = value;
    });


    return this.activatedRoute.params.subscribe(data => {
      this._route = GameComponent.REVERSE_MAPPING[data.route];
      if (!this._route) {
        this.router.navigate(['/wrong']);
      }
    });
  }

  nextRoute(value: string) {
    return GameComponent.MAPPING.get(value.toString());
  }

  route() {
    return this._route;
  }

  reindeerPicked(selection: string) {
    this.quiz.reindeer = selection;
  }

  get deer() {
    return this.quiz.reindeer;
  }

  betSelected(bet: string) {
    this.quiz.bet = bet;
  }

  retirementPlan(plan: string) {
    this.quiz.retirementPlan = plan;
  }

  smallWinnings(winnings: string) {
    this.quiz.smallWinnings = winnings;
  }

  figureItOut(figureItOut: string) {
    this.quiz.figureItOut = figureItOut;
  }


}
