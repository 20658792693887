import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StartComponent} from '../start/start.component';
import {FirstSuccessComponent} from '../end-success/first-success/first-success.component';
import {Quiz} from '../quiz.storage';

@Component({
  selector: 'app-end-fail',
  templateUrl: './end-fail.component.html',
  styleUrls: ['./end-fail.component.css']
})
export class EndFailComponent implements OnInit {
  static ROUTE = 'end';

  constructor(private router: Router, private quiz: Quiz) { }

  ngOnInit() {
  }

  get saidYes() {
    return this.quiz.figureItOut === 'yes';
  }

  @HostListener('window:keyup', ['$event'])
  keyUp(event: KeyboardEvent) {
    if (event.key === '$') {
      this.router.navigate([FirstSuccessComponent.ROUTE]);
    }
  }
}
