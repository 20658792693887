import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-money',
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.css']
})
export class MoneyComponent implements OnInit {
  static ROUTE = '$$$$$$$$$$$$';

  constructor() { }

  ngOnInit() {
  }

}
