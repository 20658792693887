import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-wrong-dialog',
  templateUrl: './wrong-dialog.component.html',
  styleUrls: ['./wrong-dialog.component.css']
})
export class WrongDialogComponent implements OnInit {

  constructor(private ref: MatDialogRef<WrongDialogComponent>) { }

  ngOnInit() {
  }

  cancel() {
    this.ref.close();
  }

}
