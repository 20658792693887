import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {MoneyComponent} from './money/money.component';
import {StartComponent} from './start/start.component';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {QuizComponent} from './quiz/quiz.component';
import {RiddleComponent} from './riddle/riddle.component';
import {Quiz, QuizStorage} from './quiz.storage';
import {EndFailComponent} from './end-fail/end-fail.component';
import {FirstSuccessComponent, ScriptService} from './end-success/first-success/first-success.component';
import {HttpClientModule} from '@angular/common/http';
import {GameComponent} from './game/game.component';
import {WrongDialogComponent} from './wrong-dialog/wrong-dialog.component';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

const appRoutes: Routes = [
  {path: StartComponent.ROUTE, component: StartComponent, pathMatch: 'full'},
  {path: EndFailComponent.ROUTE, component: EndFailComponent},
  {path: FirstSuccessComponent.ROUTE, component: FirstSuccessComponent},
  {path: MoneyComponent.ROUTE, component: MoneyComponent},
  {path: 'wrong', component: PageNotFoundComponent},
  {path: ':route', component: GameComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    MoneyComponent,
    StartComponent,
    PageNotFoundComponent,
    QuizComponent,
    RiddleComponent,
    EndFailComponent,
    FirstSuccessComponent,
    GameComponent,
    WrongDialogComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    MatDialogModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {provide: Quiz, useClass: QuizStorage},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    ScriptService,
  ],
  entryComponents: [
    WrongDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
