import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {WrongDialogComponent} from '../wrong-dialog/wrong-dialog.component';

@Component({
  selector: 'app-riddle',
  templateUrl: './riddle.component.html',
  styleUrls: ['./riddle.component.css']
})
export class RiddleComponent implements OnInit {
  @Input() password: string;
  @Input() nextRoute?: string;

  @Output() success = new EventEmitter();

  constructor(private router: Router, private dialog: MatDialog) { }

  ngOnInit() {
  }

  submit(event: Event) {
    const target = event.target as any;
    if (target.riddle.value.toLowerCase() === this.password.toLowerCase()) {
      this.success.emit();
      if (this.nextRoute) {
        this.router.navigate([this.nextRoute]);
      }

    } else {
      this.dialog.open(WrongDialogComponent);
    }

    return false;

  }

}
