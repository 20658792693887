import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {timer} from 'rxjs';
import {MoneyComponent} from '../../money/money.component';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {WrongDialogComponent} from '../../wrong-dialog/wrong-dialog.component';

export const symbols = [

  {
    'description': 'FB',
    'proName': 'NASDAQ:FB',
  },
  {
    'description': 'APPL',
    'proName': 'NASDAQ:AAPL',
  },
  {
    'description': 'NFLX',
    'proName': 'NASDAQ:NFLX',
  },
  {
    'description': 'GOOGL',
    'proName': 'NASDAQ:GOOGL',
  },
];


export const locale = 'en';


import {Injectable} from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  {name: 'stockquote', src: 'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js'},
];


declare var document: any;

@Injectable()
export class ScriptService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (this.scripts[name].loaded) {
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      } else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {
          script.innerHTML =
            '{\n' +
            '  "symbols": [\n' +
            '    {\n' +
            '      "description": "FB",\n' +
            '      "proName": "NASDAQ:FB"\n' +
            '    },\n' +
            '    {\n' +
            '      "description": "APPL",\n' +
            '      "proName": "NASDAQ:AAPL"\n' +
            '    },\n' +
            '    {\n' +
            '      "description": "NFLX",\n' +
            '      "proName": "NASDAQ:NFLX"\n' +
            '    },\n' +
            '    {\n' +
            '      "description": "GOOGL",\n' +
            '      "proName": "NASDAQ:GOOGL"\n' +
            '    }\n' +
            '  ],\n' +
            '  "locale": "en"\n' +
            '}\n';
          console.log(script.parentNode());
          console.log(script.parentNode().classList);
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

}

@Component({
  selector: 'app-first-success',
  templateUrl: './first-success.component.html',
  styleUrls: ['./first-success.component.css'],
})
export class FirstSuccessComponent implements OnInit {
  static ROUTE = 'writein';

  private _squareStock = 0;
  private _clock;

  constructor(private httpClient: HttpClient, private router: Router, private dialog: MatDialog, private scriptService: ScriptService) {
  }

  ngOnInit() {
    this._clock = timer(0, 5000);
    this._clock.subscribe(() => {
      this.httpClient.get('https://api.iextrading.com/1.0/stock/sq/delayed-quote').subscribe(result => {
        this._squareStock = result['delayedPrice'];
      });
    });


  }

  get stock() {
    return this._squareStock;
  }

  get symbols() {
    return {
      'symbols': [
        {
          'description': 'FB',
          'proName': 'NASDAQ:FB',
        },
        {
          'description': 'APPL',
          'proName': 'NASDAQ:AAPL',
        },
        {
          'description': 'NFLX',
          'proName': 'NASDAQ:NFLX',
        },
        {
          'description': 'GOOGL',
          'proName': 'NASDAQ:GOOGL',
        },
      ],
      'locale': 'en',
    };
  }

  submit(event: Event) {
    const target = event.target as any;

    if (target.stock.value.toLowerCase() === 'sq') {
      this.router.navigate([MoneyComponent.ROUTE]);
    } else {
      this.dialog.open(WrongDialogComponent);
    }

    return false;
  }

}

