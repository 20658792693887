export const Reindeer = [
  'Dasher',
  'Dancer',
  'Prancer',
  'Vixen',
  'Comet',
  'Cupid',
  'Donner',
  'Blitzen',
  'Rudolph',
];

export const Bet = [
  'iPhone',
  'Bicycle',
  'Pony',
  'Rocketship',
];


export const RetirementPlan = [
  'Start your own brew pup.',
  'Settle down, buy a house, and make reindeer babies.',
  'Go into snow business!',
  'Sell Christmas-themed crafts on Etsy.',
  'Start a band.',
];

export const SmallWinnings = [
  'Buy a beer!',
  'Nothing, it’s only $5.',
  'Save it for a rainy day.',
  'Maybe "reinvest" it with my good friend Tromple.',
];


export const FigureItOut = [
  'yes',
  'no',
  'I thought I had.',
];

export abstract class Quiz {
  reindeer: string;
  bet: string;
  retirementPlan: string;

  smallWinnings: string;

  figureItOut: string;
}


export class QuizStorage extends Quiz {
  reindeer: string;

  bet: string;

  retirementPlan: string;

  smallWinnings: string;

  figureItOut: string;
}
